.set-pass-container {
  height: 100vh;

  &-header {
    width: 100%;
    position: fixed;
    height: 70px;
    box-sizing: border-box;
    background-color: #00c9cc;

    p {
      margin: 0;
    }

    .logo-container{
      width: 38px;
      height: 32px;
      margin: 18px;
      overflow: hidden;

    }
  }


  .info-container {
    display: flex;
    align-items: center;
    padding: 110px 20px 120px;
    box-sizing: border-box;
    font: 18px MaisonNeue-Book, sans-serif;
    .personal-info {
      margin-bottom: 50px;
      p {
        font: 18px MaisonNeue-Book, sans-serif;
      }
    }

    &-text {
      .head-text {
        font-weight: 600;
      }

      width: 100%;
      max-width: 650px;
      text-align: left;
      margin: 0 auto;
      line-height: 32px;
      color: #393939;
    }

    .add-text {
      font-size: 14px;
      margin-top: 60px;
    }

    .save-password {
      display: flex;
    }

    .set-up-btn {
      background-color: #00c9cc;
      color: #ffffff;
      margin: 15px auto;
      border-radius: 0;
      margin-left: 20px;
      min-width: 85px;
    }

    .set-up-btn:hover {
      opacity: 0.4;
    }
  }

  &-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #3B3B3B;
    box-shadow: 0 0 5px 0 #000000;

    .accept-btn {
      background-color: #ffffff;
      border-radius: 0;
    }

    .logo-container {
      width: 100%;
      max-width: 165px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 30px;

      img:first-child {
        width: 100%;
        max-width: 84px;
        margin-bottom: 10px;
      }
    }
  }
}
